import React from "react"
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import TourMenu from '../../components/TourMenu';
import { Helmet } from "react-helmet"
import {Link} from 'gatsby';

export default function CreateTest() {

    return (

        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Feature tour - get started | Skillmeter</title>
        </Helmet>
                
        <Header />

        <section className="py-12 bg-gray-50 sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl ">
              Feature tour
            </h2>
            <p className="mt-6 text-lg font-normal text-gray-600 ">
            See how easy is to get started with Skillmeter
          </p>               
          </div>
  
          <div className="max-w-5xl mx-auto mt-10 sm:mt-20">
            <div className="flow-root">
            <div className="-my-8 divide-y divide-gray-200">
                <div className="py-8">
                  <div className="flex">
                    <div className="w-1/4">
                        <TourMenu option="2" />

                    </div>
                    <div className="w-3/4">
                      <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl ">
                      Create a test
                      </h2>               
                      <br /><br />       
                      Skillmeter offers everything you need to create and assign customized assessments to specific candidates.
                      <br /><br />       

                      We currently offer 2 types of tests: questionnaire test and programming test.
                      <br /><br />       

                      <b>Questionnaire test</b>
                      <br />       
                      A questionnaire test is a test comprised of a set of questions, similar to a paper test. The candidate will have to answer each question either by selecting the right answer, either by writing the answer, depending on the question type.
                      <br /><br />       
                      <b>Programming test</b>
                      <br />
                      A programming test is a "hands-on" test that allows you to measure the coding ability of your candidates. The candidate will be provided with an online editor that allows code execution in real-time. Skillmeter supports today programming tests in C++, C#, Java, Perl, Python, PHP, Ruby or VB.NET.


                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>
  
        <Footer />
      </>
    )
}